<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="90px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="form.productName" clearable placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单编号" prop="orderNo">
              <el-input v-model="form.orderNo" clearable placeholder="请输入订单编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="退款状态" prop="status">
              <el-select v-model="form.status" clearable placeholder="请选择退款状态">
                <el-option v-for="item in $store.state.refundState"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list" style="min-height: calc(100vh - 276px)">
      <el-table
          :data="table"
          style="width: 100%">
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
        </el-table-column>
        <el-table-column
            prop="paymentAmount"
            align="center"
            label="实际支付价格">
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
        </el-table-column>
        <el-table-column
            prop="phoneNo"
            align="center"
            label="用户手机号">
        </el-table-column>
        <el-table-column
            prop="orderNo"
            align="center"
            label="订单编号">
        </el-table-column>
        <el-table-column
            prop="status"
            align="center"
            label="退款状态">
          <template scope="scope">
            <span>{{ $store.state.refundState.filter(item => { return item.dictKey == scope.row.status })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                v-if="scope.row.status == 0"
                title="此订单是否要进行退款？"
                @confirm="refund(scope.row)"
            >
              <el-button slot="reference" type="text" size="small" >退款</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Finance",
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        orderNo: null, // 订单编号
        productCategory: null, // 产品类别
        productName: null, // 产品名称
        status: null, // 订单状态
      },
      table: [],
      total: 0,
    }
  },
  created() {
    this.getList(this.form);
  },
  mounted() {

  },
  methods: {
    getList(e) {
      this.$get("finance",e)
        .then(res => {
          if(res) {
            console.log(res);
            this.total= res.data.total;
            this.table= res.data.list;
          }
        })
    },
    onSubmit(e) {
      e.pageNum= 1;
      this.getList(e);
    },
    handleSizeChange(e) {
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.getList(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.getList(this.form);
    },
    refund(e) {
      console.log(e);

      let obj = {};
      obj["id"]= e.id;
      obj["status"]= 1;

      this.$put("finance/edit",obj)
        .then(res => {
          if(res) {
            console.log(res);
            this.$message.success(res.message);
            this.getList(this.form);
          }
        })

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
